<template>
  <div class="bg-white">
    <div v-show="isLoading">
      <OtherLoading />
    </div>
    <div v-show="!isLoading" class="p-3 pre-box title-panel">
      <b-row>
        <b-col sm="3 border-right">
          <label>Total Customer</label>
          <div>
            <span class="text-score">
              {{ analysis.total_customer | numeral("0,0") }}
            </span>
            <!-- <span>Time(s)</span> -->
          </div>
        </b-col>
        <b-col sm="3 border-right">
          <label>Total Complete</label>
          <div>
            <span class="text-score">
              {{ analysis.total_complete | numeral("0,0") }}
              ({{ analysis.persent_complete }}%)
            </span>
            <!-- <span>Time(s)</span> -->
          </div>
        </b-col>
        <b-col sm="3 border-right">
          <label>Average progress</label>
          <div>
            <span class="text-score"
              >{{ analysis.average_progress.toFixed(2) }}
            </span>
            <!-- <span>Time(s)</span> -->
          </div>
        </b-col>
        <b-col sm="3">
          <label> Redeem Reward Customer</label>
          <div>
            <span class="text-score">
              {{ analysis.redeem_reward_customer | numeral("0,0") }}
              ({{ analysis.persent_redeem_reward_customer }}%)
            </span>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="pl-3 pr-3 pt-3">
      <b-input-group>
        <b-form-input
          class="search-bar"
          @keyup.enter="getDetail"
          placeholder="Search Name, Member ID, Tel., Email"
          v-model="filter.search"
        >
        </b-form-input>
        <b-input-group-append is-text class="mr-2">
          <b-iconstack font-scale="2" type="submit" @click="getDetail">
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-append>
        <b-button v-b-toggle.sidebar-filter class="btn button btn-mobile px-4">
          <font-awesome-icon
            icon="filter"
            title="filter-btn"
            class="main-color mr-0 mr-sm-1"
          />
          <span class="d-none d-sm-inline">Filter</span>
        </b-button>
        <b-dropdown id="dropdown" right class="btn-mobile ml-2">
          <template #button-content>
            <span class="d-none d-md-inline-flex btn-text mr-1">Export</span>
          </template>

          <b-dropdown-item @click="exportData">
            <b-button variant="mobile">
              <!-- <font-awesome-icon icon="plus" class="text-black d-sm-none" /> -->
              <span class="d-none d-sm-block">
                <font-awesome-icon icon="file-upload" class="pointer" />
                Export
              </span>
            </b-button>
          </b-dropdown-item>
          <b-dropdown-item @click="exportDetail">
            <b-button variant="mobile">
              <font-awesome-icon icon="plus" class="text-black d-sm-none" />
              <span class="d-none d-sm-block">
                <font-awesome-icon icon="file-upload" class="pointer" />
                Export Detail
              </span>
            </b-button>
          </b-dropdown-item>
        </b-dropdown>
      </b-input-group>
    </div>

    <div v-show="!isLoading" class="p-3">
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="reportList"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(user_mission_id)="row">
          <b-button
            size="sm"
            @click="row.toggleDetails"
            class="mr-2"
            variant="icon"
          >
            <font-awesome-icon icon="chevron-up" v-if="row.detailsShowing" />
            <font-awesome-icon icon="chevron-down" v-else />
            <!-- {{ row.detailsShowing ? "Hide" : "Show" }} Details -->
          </b-button>
        </template>
        <template v-slot:cell(customer_name)="data">
          <div v-if="data.item.customer_name">
            {{ data.item.customer_name }}

            <br />
            <router-link :to="'/report/customer/' + data.item.user_guid">
              <b-button variant="link" class="name-link">
                {{ data.item.member_id }}
              </b-button>
            </router-link>
          </div>
          <div v-else>
            <p class="m-0">-</p>
          </div>
        </template>

        <template v-slot:cell(progress)="data">
          {{
            data.item.persent_progress % 1 == 0
              ? data.item.persent_progress
              : data.item.persent_progress.toFixed(2)
          }}% ({{ data.item.progress }})
        </template>
        <template v-slot:cell(claim_reward)="data">
          {{ data.item.claim_reward }}
        </template>
        <template v-slot:cell(status_name)="data">
          <p class="m-0" v-if="data.item.status_name">
            <span v-if="data.item.status < 3" class="text-warning">{{
              data.item.status_name
            }}</span>
            <span v-else-if="data.item.status < 6" class="text-success">{{
              data.item.status_name
            }}</span>
            <span v-else class="text-danger">{{ data.item.status_name }}</span>
          </p>
          <p v-else class="m-0">-</p>
        </template>
        <template v-slot:cell(valid_from)="data">
          <p class="m-0" v-if="data.item.valid_from">
            <span
              >{{ data.item.valid_from | moment("DD/MM/YYYY") }} <br />
              {{ data.item.valid_from | moment("HH:mm:ss") }}</span
            >
          </p>
          <p v-else class="m-0">-</p>
        </template>
        <template v-slot:cell(valid_to)="data">
          <p class="m-0" v-if="data.item.valid_to">
            <span
              >{{ data.item.valid_to | moment("DD/MM/YYYY") }} <br />
              {{ data.item.valid_to | moment("HH:mm:ss") }}</span
            >
          </p>
          <p v-else class="m-0">-</p>
        </template>
        <template v-slot:cell(status)="data">
          <span v-if="data.item.status == 1" class="text-success">
            Active
          </span>
          <span v-else-if="data.item.status == 2" class="text-danger">
            Suspend
          </span>
          <span v-else class="text-danger"> Suspended </span>
        </template>
        <template v-slot:cell(action)="data">
          <div class="d-flex align-items-center justify-content-center">
            <font-awesome-icon
              icon="level-up-alt"
              title="Adjust Mission Progress"
              class="text-gray"
              v-if="
                data.item.is_complete == 1 ||
                (data.item.persent_progress == 100 && data.item.status == 1)
              "
            />
            <font-awesome-icon
              icon="level-up-alt"
              title="Adjust Mission Progress"
              class="text-custom-primary cursor-pointer"
              v-else-if="data.item.status == 1"
              @click="adjustMission(data.item)"
            />
            <font-awesome-icon
              v-if="data.item.status == 1"
              icon="user-times"
              title="Permanent Suspend"
              class="ml-2 text-danger cursor-pointer"
              @click="suspendUser(data.item)"
            />
          </div>
        </template>
        <template #row-details="row">
          <b-card>
            <b-table
              responsive
              striped
              hover
              :fields="subField"
              :items="row.item.customer_detail"
              show-empty
              :per-page="filterDetail[row.index].RecordPerPage"
              :current-page="filterDetail[row.index].PageNumber"
              empty-text="No matching records found"
            >
              <template v-slot:cell(invoice_no)="data">
                {{ data.item.invoice_no || "Free Stamp" }}
              </template>
              <template v-slot:cell(branch_name)="data">
                {{ data.item.branch_name || "-" }}
              </template>
              <template v-slot:cell(grand_total)="data">
                {{ data.item.grand_total | numeral("0,0.00") }}
              </template>
              <template v-slot:cell(progress)="data">
                {{ data.item.progress }}
              </template>

              <template v-slot:cell(created_time)="data">
                <p class="m-0" v-if="data.item.created_time">
                  <span
                    >{{ data.item.created_time | moment("DD/MM/YYYY") }} <br />
                    {{ data.item.created_time | moment("HH:mm:ss") }}</span
                  >
                </p>
                <p v-else class="m-0">-</p>
              </template>

              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="(val) => changePerPage(row.index, val)"
              @pagination="() => null"
              :filter="filterDetail[row.index]"
              :rows="row.item.customer_detail.length"
              page="PageNumber"
              take="RecordPerPage"
            />
          </b-card>
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="changeRecord"
        @pagination="pagination"
        :filter="filter"
        :rows="rows"
        page="PageNumber"
        take="RecordPerpage"
      />
    </div>
    <b-sidebar
      id="sidebar-filter"
      v-model="sidebarShow"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      ref="filterSidebar"
      aria-labelledby="sidebar-filter-title"
      no-header
      width="80vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter">Filter</b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="clearFilter">
              x <span class="text-under">Clear Filter</span>
            </button>
          </b-col>
        </b-row>
        <div class="mt-3">
          <MultiSelect
            textFloat="Last Purchase Branch"
            :options="branchListOption"
            textField="name"
            valueField="id"
            placeholder="Select Branch"
            v-model="filter.last_purchase_branch"
            :value="filter.last_purchase_branch"
          />
        </div>
        <div class="mt-3">
          <MultiSelect
            textFloat="First Purchase Branch"
            :options="branchListOption"
            textField="name"
            valueField="id"
            placeholder="Select Branch"
            v-model="filter.first_purchase_branch"
            :value="filter.first_purchase_branch"
          />
        </div>

        <div class="mt-3">
          <InputRange
            ref="rangeInput"
            v-if="sidebarShow"
            textFloat="Progress"
            :from="filter.progress_from"
            :to="filter.progress_to"
            @from="(val) => (filter.progress_from = val)"
            @to="(val) => (filter.progress_to = val)"
            :max="maxRange"
          />
        </div>
        <div class="mt-3">
          <InputDatePickerFilter
            textFloat="Last Purchase Date From"
            name="last-purchase-date-from"
            ref="last-purchase-date-from"
            :value="filter.last_purchase_date_from"
            @input="(val) => (filter.last_purchase_date_from = val)"
          />
        </div>
        <div class="mt-3">
          <InputDatePickerFilter
            textFloat="Last PurchasDate To"
            name="last-purchase-date-to"
            ref="last-purchase-date-to"
            :value="filter.last_purchase_date_to"
            @input="(val) => (filter.last_purchase_date_to = val)"
          />
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click.prevent="hide"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              @click.prevent="submitFilter"
              block
              variant="light"
              id="handle-search-filter"
              class="text-body rounded-pill btn-main"
            >
              Search
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
    <ModalImportFile ref="modalImportFile" :id="id" />
    <ModalUpdateNote
      ref="modalUpdateNote"
      :form="filterNote"
      :v="$v"
      v-if="!loadingModalNote"
      @updateNote="updateNote"
    />
    <b-modal
      ref="adjustMission"
      centered
      body-class="p-4"
      v-model="modalAdjustMission"
      title="Adjust Mission Progress"
      @hidden="clearValue()"
    >
      <InputText
        textFloat="Add Progress"
        customLabel
        v-model="adjustMissionDetail.adjust_step"
        placeholder="0"
        type="number"
        class="mb-0"
        :v="$v.adjustMissionDetail.adjust_step"
        :isValidate="$v.adjustMissionDetail.adjust_step.$error"
        @input="(value, e) => handleProgress(value, e)"
      >
        <template #textFloat>
          <div class="current-mission">
            <small>Current : {{ adjustMissionDetail.progress }}</small>
          </div>
        </template>
      </InputText>

      <template #modal-footer>
        <b-row class="justify-content-between align-items-center w-100">
          <b-button
            @click="modalAdjustMission = false"
            class="btn-cancel btn-width"
          >
            Cancel
          </b-button>
          <b-button
            class="btn-confirm"
            @click.prevent="submitMission"
            variant="save"
          >
            Confirm
          </b-button>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputRange from "@/components/inputs/InputRange";
import OtherLoading from "@/components/loading/OtherLoading";
import ModalUpdateNote from "@/components/redeem/modal/ModalUpdateNote";
import ModalImportFile from "@/components/redeem/modal/ModalImportFile";
import { required } from "vuelidate/lib/validators";
export default {
  name: "MissionReport",
  components: {
    InputRange,
    OtherLoading,
    ModalUpdateNote,
    ModalImportFile,
  },
  props: {
    form: {
      required: false,
    },
  },
  validations: {
    filterNote: {
      note: { required },
    },
    adjustMissionDetail: {
      adjust_step: { required },
    },
  },
  computed: {
    maxRange() {
      if (this.form.mission_detail)
        return this.form.mission_detail.filter(
          (el) => el.mission_detail_type_id == 4
        ).length;
      return 0;
    },
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      data: {},
      sidebarShow: false,
      id: parseInt(this.$route.params.id),
      filter: {
        mission_id: parseInt(this.$route.params.id),
        user_mission_id: 0,
        last_purchase_branch: [],
        first_purchase_branch: [],
        progress_from: 0,
        progress_to: this.form.mission_detail.filter(
          (el) => el.mission_detail_type_id == 4
        ).length,
        last_purchase_date_from: null,
        last_purchase_date_to: null,
        Search: "",
        PageNumber: 1,
        RecordPerpage: 5,
      },
      filterDetail: [],

      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      branchListOption: [],
      subField: [
        {
          key: "invoice_no",
          label: "Transaction No.",
          thClass: "text-nowrap",
        },

        {
          key: "progress",
          label: "Progress",
          thClass: "text-nowrap",
        },
        {
          key: "grand_total",
          label: "Grand Total",
          thClass: "text-nowrap",
        },
        {
          key: "branch_name",
          label: "Branch",
          thClass: "text-nowrap",
        },
        {
          key: "created_time",
          label: "Create Time",
          thClass: "text-nowrap",
        },
      ],
      fields: [
        {
          key: "user_mission_id",
          label: "",
          thClass: "text-nowrap",
        },
        {
          key: "customer_name",
          label: "Name / Member ID",
          thClass: "text-nowrap",
        },
        {
          key: "progress",
          label: "Progress",
          thClass: "text-nowrap",
        },
        {
          key: "valid_from",
          label: "Mission Join Date",
          thClass: "text-nowrap",
        },
        {
          key: "valid_to",
          label: "Expiration Date",
          thClass: "text-nowrap",
        },
        {
          key: "claim_reward",
          label: "Claimed Reward",
          thClass: "text-nowrap",
        },
        {
          key: "status",
          label: "Status",
          thClass: "text-nowrap",
        },
        {
          key: "action",
          label: "Action",
          thClass: "text-nowrap",
        },
      ],
      reportList: [],
      ListIdAll: [],
      ListAll: [],
      selectAllProduct: 0,
      selectItem: [],

      filterNote: {
        redeem_order_id: 0,
        note: null,
      },
      loadingModalNote: true,
      pickUpType: "",
      analysis: {
        total_customer: 0,
        total_complete: 0,
        persent_complete: 0,
        average_progress: 0,
        redeem_reward_customer: 0,
        persent_redeem_reward_customer: 0,
      },
      modalAdjustMission: false,
      adjustMissionDetail: {},
    };
  },

  created() {
    this.getDetail();
    this.getBranchList();
    this.getAnalysis();
  },
  methods: {
    async getAnalysis() {
      // this.isBusy = true;

      const resp = await this.axios.post(
        "/mission/report_analysis",
        this.filter
      );

      this.analysis = resp.data.detail;
      // this.reportList = data.detail.customer_list;

      // this.isBusy = false;
    },
    async getDetail() {
      this.isBusy = true;

      const resp = await this.axios.post(
        "/mission/report_customer_list",
        this.filter
      );
      this.filterDetail = [];
      const data = resp.data;
      this.rows = data.detail.total;
      this.reportList = data.detail.customer_list;
      for (const report of data.detail.customer_list) {
        this.filterDetail.push({
          PageNumber: 1,
          RecordPerPage: 5,
        });
      }
      this.isBusy = false;
    },
    async getBranchList() {
      await this.$store.dispatch("getBranchList", null);
      const data = this.$store.state.redeem.branchList;
      if (data.result == 1) {
        this.branchListOption = this.branchListOption.concat(data.detail);
      }
    },
    async exportData() {
      // this.isLoading = true;
      try {
        this.$bus.$emit("showLoading");
        const { data } = await this.axios.post(
          "/mission/report_customer_list/export",
          this.filter,
          {
            responseType: "blob",
          }
        );
        // if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data])); // change object.
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `mission-` + this.$route.params.id + `.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        this.$bus.$emit("hideLoading");
      } catch (error) {
        this.errorAlert(error.message);
      }
    },
    async exportDetail() {
      this.isLoading = true;
      const { data } = await this.axios.post(
        "/mission/report_customer_detail/export",
        this.filter,
        {
          responseType: "blob",
        }
      );
      // if (data.status == 200) {
      var fileURL = window.URL.createObjectURL(new Blob([data])); // change object.
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `mission-detail-` + this.$route.params.id + `.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      // } else {
      //   alert("Export fail");
      // }
      this.isLoading = false;
    },
    confirmAction(status) {
      this.action.to_status = status;
      this.$emit("confirmAction", null);
    },
    async changeStatus() {
      this.isLoading = true;
      await this.$store.dispatch("updateStatusRedeem", this.action);
      const data = this.$store.state.redeem.updateStatusRedeem;
      if (data.result == 1) {
        this.isLoading = false;
        this.selectAllProduct = 0;
        this.action.list_redeem_order = [];
        this.actionDisabled = true;
        this.onlyAction = true;
        await this.getDetail();
      } else {
        alert(data.message);
      }
    },

    selectProductTable(value) {
      this.selectAllProduct = this.ListIdAll.length == value.length ? 1 : 0;
      this.checkDisabledAction();
      // กรองว่ามีสถานะ ยกเลิก ไหม
    },

    pagination(page) {
      this.filter.page = page;
      this.getDetail();
    },
    async handlePerpage(value) {
      this.filter.page = 1;
      this.filter.length = value;
      this.getDetail();
    },
    async submitFilter() {
      this.filter.page = 1;
      this.$refs.filterSidebar.hide();
      this.getAnalysis();
      this.getDetail();
    },
    hide() {
      this.$refs.filterSidebar.hide();
    },
    clearFilter() {
      this.filter = {
        mission_id: parseInt(this.$route.params.id),
        user_mission_id: 0,
        last_purchase_branch: [],
        first_purchase_branch: [],
        progress_from: 0,
        progress_to: this.form.mission_detail.filter(
          (el) => el.mission_detail_type_id == 4
        ).length,
        last_purchase_date_from: null,
        last_purchase_date_to: null,
        Search: "",
        PageNumber: 1,
        RecordPerpage: 5,
      };
      this.$refs.rangeInput.initSlide();
      this.getDetail();
      this.getAnalysis();
      this.hide();
    },
    async getNote(item) {
      this.loadingModalNote = true;
      await this.$store.dispatch("getNoteRedeem", item.redeem_order_id);
      const data = this.$store.state.redeem.stateNoteRedeem;
      if (data.result === 1) {
        this.loadingModalNote = false;
        this.filterNote.note = data.detail;
        this.filterNote.redeem_order_id = item.redeem_order_id;
        this.$nextTick().then(() => {
          this.$refs.modalUpdateNote.show();
        });
      } else {
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    async updateNote(val) {
      await this.$store.dispatch("updateNoteRedeem", val);
      const data = this.$store.state.redeem.stateUpdateNoteRedeem;
      if (data.result === 1) {
        this.$swal(data.message, {
          icon: "success",
        });
        this.$nextTick().then(() => {
          this.$refs.modalUpdateNote.hide();
        });
      } else {
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    importData() {
      this.$refs.modalImportFile.show();
    },
    address(item) {
      let address = `${item.home_address || ""} ${item.subdistrict || ""} ${
        item.district || ""
      } ${item.province || ""} ${item.zip_code || ""}`;
      return address.trim() ? address : "-";
    },
    changeRecord() {
      this.getDetail();
    },
    pagination(val) {
      this.filter.PageNumber = val;
      this.getDetail();
    },
    changePerPage(index, val) {
      this.filterDetail[index].RecordPerPage = val;
    },
    async suspendUser(items) {
      this.confirmAlert({
        message: `You want to suspend ${items.customer_name} mission`,
        title: "Are you sure?",
        icon: "warning",
        confirmButtonText: "Cofirm",
        cancelButtonText: "Cancel",
      }).then(async (val) => {
        if (val.isConfirmed) {
          this.$bus.$emit("showLoading");
          const res = await this.axios.put(
            `/mission/update_user_status/${items.user_mission_id}`
          );
          this.$bus.$emit("hideLoading");
          if (res.data.result) this.getDetail(), this.successAlert();
          else this.errorAlert(res.data.message);
        }
      });
    },
    adjustMission(items) {
      this.adjustMissionDetail = { ...items, adjust_step: "" };
      this.modalAdjustMission = true;
    },
    async submitMission() {
      this.$v.adjustMissionDetail.$touch();
      if (this.$v.adjustMissionDetail.$error) return;
      this.$bus.$emit("showLoading");
      const res = await this.axios.put(
        `/mission/add_progress/${this.adjustMissionDetail.user_mission_id}/${this.adjustMissionDetail.adjust_step}/${this.form.mission_redeem_type}`
      );
      this.$bus.$emit("hideLoading");
      if (res.data.result) {
        this.modalAdjustMission = false;
        this.getDetail();
        this.successAlert();
      } else this.errorAlert(res.data.message);
    },
    handleProgress(val, e) {
      let value =
        this.adjustMissionDetail.total_stamp -
        this.adjustMissionDetail.current_stamp;
      if (val > value) {
        this.$nextTick(() => {
          e.target.value = value;
          this.adjustMissionDetail.adjust_step = value;
          return e.preventDefault();
        });
      }
    },
    clearValue() {
      this.$v.adjustMissionDetail.$reset();
      this.adjustMissionDetail = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
label {
  font-weight: bold;
}
.pre-box {
  background: var(--theme-secondary-color) !important;
  color: var(--font-color) !important;
}

.cb-table {
  position: relative;
  margin-left: 15px;
  padding-top: 15px;
}
::v-deep .btn-secondary.dropdown-toggle {
  background-color: white;
  border-color: var(--primary-color);
}
.text-gray {
  color: gray;
}
.current-mission {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
